import { ICollection, IProduct } from '../components/shop/shop.interface'

import { IHomePage } from '../components/index/index.interface'
import React, { Suspense } from 'react'
import Seo from '../components/Seo'
import { graphql } from 'gatsby'
import useWindowWidth from '../components/shared/useWindowSize'

const IndexComponent  = React.lazy(() => import('../components/index/index-component'))

type Props = {
  data: {
    allContentfulHomePage: {
      nodes: IHomePage[]
    }
    allShopifyProduct: {
      nodes: IProduct[]
    }
    homepageCollections: {
      nodes: ICollection[]
    }
    producents: any
    fullsizePromo: {
      nodes: ICollection[]
    }
  }
}

const Index: React.FC<Props> = ({ data }: Props) => {
  const content = data.allContentfulHomePage.nodes[0]
  const homepageCollections: ICollection[] = data.homepageCollections.nodes.sort((a, b) => {
    const aOrder = parseInt(a.metafields?.find(metafield => metafield.key === 'homepage_view_order')?.value || '0');
    const bOrder = parseInt(b.metafields?.find(metafield => metafield.key === 'homepage_view_order')?.value || '0');
    return bOrder - aOrder;
  });

  const width = useWindowWidth();

  return (
    <div>
      <Seo
        title={'Vine der bevarer lysten til et glas mere!'}
        description={`Mansted wine - Vine der bevarer lysten til et glas mere! Køb online her! Primært europæisk vine, tilsat balance, friskhed, saftighed! Altid gode priser`}
        image={'https://www.manstedwine.dk/logo/mansted-wine-logo.jpg'}
      />
      <Suspense fallback={<div>Loading...</div>}>
      <IndexComponent data={content} producenter={data.producents} collections={homepageCollections} fullsizePromo={data.fullsizePromo.nodes[0]} /> 
      </Suspense>
    </div>
  )
}
export default Index

export const HomePage = graphql`
  query {
    homepageCollections: allShopifyCollection(
      filter: {metafields: {elemMatch: {key: {eq: "show_on_homepage"}, value: {ne: "0"}}}}
    ) {
      nodes {
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 1200)
          originalSrc
        }
        metafields {
          value
          key
        }
        description
        descriptionHtml
        title
        products {
          descriptionHtml
          variants {
            storefrontId
            availableForSale
            inventoryQuantity
            compareAtPrice
            price
            selectedOptions {
              name
              value
            }
            image {
              gatsbyImageData
              originalSrc
            }
          }
          metafields {
            value
            key
          }
          featuredImage {
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 130)
            originalSrc
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          title
          vendor
          tags
          collections {
            title
            metafields {
              value
            }
          }
        }
      }
    }
    allShopifyProduct(limit: 3, sort: { fields: [publishedAt], order: DESC }, filter: { collections: { elemMatch: { title: { eq: "Vine" } } } }) {
      nodes {
        descriptionHtml
        variants {
          storefrontId
          availableForSale
          inventoryQuantity
          compareAtPrice
          price
          selectedOptions {
            name
            value
          }
          image {
            gatsbyImageData
            originalSrc
          }
        }
        metafields {
          value
          key
        }
        featuredImage {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 130)
          originalSrc
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        title
        vendor
        tags
        collections {
          title
          metafields {
            value
          }
        }
      }
    }
    fullsizePromo: allShopifyCollection(filter: { title: { eq: "Forside tilbud" } }) {
      nodes {
        title
        metafields {
          value
          key
        }
        products {
          metafields {
            value
            key
          }
        }
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 1200)
        }
      }
    }
    producents: allShopifyCollection(limit: 6, filter: { metafields: { elemMatch: { value: { eq: "producent" } } } }) {
      nodes {
        title
        descriptionHtml
        image {
          originalSrc
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 300)
        }
      }
    }
    allContentfulHomePage {
      nodes {
        description {
          description
        }
        title
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 500)
        }
        popularWines {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 1200)
        }
        newestWines {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 1200)
        }
        bestSellingImage {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 1200)
        }
        saleImg {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 1200)
        }
      }
    }
  }
`
